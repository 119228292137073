import Proj1 from "../assets/proj1.jpg";
import Proj2 from "../assets/proj2.jpg";
import Proj3 from "../assets/proj3.jpg";
import Proj4 from "../assets/proj4.jpg";


export const ProjectList = [
  {
    name: "Dijkstra Algorithm Visualizer",
    image: Proj1,
    skills: "JavaScript,HTML,CSS",
  },
  {
    name: "Ecommerce Website",
    image: Proj2,
    skills: "React,Node.js,MongoDB",
  },
  {
    name: "Spotify Clone",
    image: Proj3,
    skills: "React,Node.js,MongoDB,SpotifyAPI",
  },
  {
    name: "Social Media Website",
    image: Proj4,
    skills: "React,Node.js,MySQL,GraphQL",
  },
];